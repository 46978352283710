interface Props {
    file: String;
    pageNumber?: Number;
}

const PdfLoader = ({ file, pageNumber = 1 }: Props) => {
    return (
        <iframe
            key={`${file}#page${pageNumber}`}
            src={`${file}#page=${pageNumber}`}
            width="100%"
            height="100%"
            style={{ border: "none" }}
            title="PDF Viewer"
        ></iframe>
    );
};

export default PdfLoader;
