import axios from "axios";
import { apiPromise } from "./helpers";
import Environment from "../enviroment";

let instance = axios.create({
    headers: { "Content-Type": "application/json; charset=utf-8" },
});
export const source = axios.CancelToken;

const base = Environment.USER_URL;
const query = Environment.QUERY_URL;
const socket = Environment.socketUrl;
const apiURL = Environment.API_URL;
const claimsURL = Environment.claims;
const claimsSocketURL = Environment.claimsSocketUrl;
const STRUCTURED_URL = Environment.STRUCTURED_URL;
const STRUCTURED_SOCKET = Environment.STRUCTURED_SOCKET;
const UNSTRUCTURED_URL = Environment.UNSTRUCTURED_URL;
const SOFIE_SOCKET = Environment.SOFIE_SOCKET;
export class Api {
    // #structured endpoints //
    private static getAllTables: string = `${STRUCTURED_URL}/get_all_ingested_files`;
    static esgSocketUrl: string = `${STRUCTURED_SOCKET}/sql_chat_bot`;
    private static uploadESGExcel: string = `${STRUCTURED_URL}/upload_excel`;
    // private static getGeneralQuery: string = `${base}/get_general_data`;
    // private static askSearch: string = `${base}/get_sql_output`;
    // private static getVisualizedQuery: string = `${base}/get_visualization_output`;
    private static postFinanceExcel: string = `${STRUCTURED_URL}/upload_excel_without_mapping`;
    private static detect_anomalies: string = `${STRUCTURED_URL}/detect_anomalous_data`;
    private static detect_outliers: string = `${STRUCTURED_URL}/detect_outliers`;
    private static detect_rounded = ({ page = 1, size = 100 }) =>
        `${STRUCTURED_URL}/find_rounded_entries?page=${page}&size=${size}`;
    private static detect_credits_debits: string = `${STRUCTURED_URL}/unexpected_credits_debits`;
    private static detect_weekly_gap: string = `${STRUCTURED_URL}/flag_unusual_entries`;
    private static detect_exceptional_entries: string = `${STRUCTURED_URL}/find_exceptional_entries`;
    private static anomaly_chat_bot: string = `${STRUCTURED_URL}/anomaly_chat_bot`;

    // AI Contact Center
    static contactCenterSocketUrl = `https://unstruct-api-ssx.softsensor.ai`;
    static fetchLogs = () => {
        return apiPromise(() => instance.get(`${UNSTRUCTURED_URL}/fetch_dashboard?limit=10`));
    };
    static fetchCallDetails = (id: string) => {
        return apiPromise(() => instance.get(`${UNSTRUCTURED_URL}/get-call-details/${id}`));
    };
    static updateCallDetails = (data: any) => {
        return apiPromise(() => instance.post(`${UNSTRUCTURED_URL}/update_call_details`, data));
    };

    // #claims //
    private static claimExistingProjects: string = `${claimsURL}/claims/existing_projects`;
    private static claimExistingProjectsPdf: string = `${claimsURL}/claims/project_pdfs`;
    private static claimExistingProjectsPdfSummary: string = `${claimsURL}/claims/summary_problem_statement`;
    private static claimExistingProjectsFilter: string = `${claimsURL}/claims/auto_filter`;
    private static claimExistingProjectsPdfReference: string = `${claimsURL}/claims/reference`;
    private static claimExistingProjectsPdfCompare: string = `${claimsURL}/claims/compare_report`;
    private static claimExistingPdfLibrary: string = `${claimsURL}/claims/library`;
    private static claimExistingDelayEvent: string = `${claimsURL}/claims/delay_event`;
    private static claimCreateLibraryProject: string = `${claimsURL}/claims/upload`;
    private static claimSubDelayEvent: string = `${claimsURL}/claims/sub_delay_event`;
    static getSubDelayEvent(data: any) {
        return apiPromise(() => instance.post(this.claimSubDelayEvent, data));
    }
    static downloadClaimFile(data: any) {
        return apiPromise(() => instance.post(`${claimsURL}/claims/download_letter`, data));
    }
    static document(data: any) {
        return apiPromise(() => instance.post(`${claimsURL}/claims/get_pdf`, data, { responseType: "arraybuffer" }));
    }
    static getProjectCounts(id: string) {
        return apiPromise(() => instance.get(`${claimsURL}/claims/existing_projects_counts/${id}`));
    }
    static getEventCounts() {
        return apiPromise(() => instance.get(`${claimsURL}/claims/delay_event_count`));
    }

    // #care //
    private static care_upload: string = `${base}/care-usecase`;
    private static get_care_detail: string = `${base}/care-usecase-answer`;

    // #compare and contrast
    private static compareandcontrast = `${base}/compare-contrast`;

    // #bandhan
    static uploadBandhan = (formData: FormData) => {
        return apiPromise(() =>
            instance.post(`${UNSTRUCTURED_URL}/upload_bandhan`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    };
    static uploadProgress = (id: string) => {
        return apiPromise(() => instance.get(`${UNSTRUCTURED_URL}/get_file_upload_status/${id}`));
    };
    static getDamageDetectionReports(userId: string, start: number, interval: number) {
        return apiPromise(() =>
            instance.post(
                `https://softx-demo.softsensor.ai/bond_llm/user_older_reports?user_id=${userId}&start=${start}&interval=${interval}`
            )
        );
    }
    static chatBandhan = (data: any) => {
        return apiPromise(() => instance.post(`${UNSTRUCTURED_URL}/bandhan_infer`, data));
    };

    static baseUrl: string = `${base}/namespaces`;
    static socketUrl: string = `${socket}/pdf_chat_bot`;
    static getImageAnalysis: string = `${socket}/image_chat_bot`;
    static sofieUrl: string = `${SOFIE_SOCKET}/food_chat_bot`;
    static claimsSocketUrl: string = `${claimsSocketURL}/claims/claims_chat_bot`;
    static claimsProgressSocketUrl: string = `${claimsSocketURL}/claims/progress`;
    static setFinetuneSettings: string = `${socket}/finetune_socket`;
    static reportSocket: string = `${socket}/lmd_chat_bot`;
    static varianceSocketUrl: string = `https://struct-api-ssx.softsensor.ai/structured_data/variance_analysis_bot`;
    static audioChatBot: string = `${socket}/audio_chat_bot`;
    static upload: string = `${base}/textract_upload_pdf`;
    static uploadPdfSocket: string = `${socket}/upload_socket`;
    static fabricSocket: string = `${socket}/azure_fabric_chat_bot`;
    static mongoSource: string = `${base}/upload_mongo`;
    static googleSheetsSource: string = `${base}/upload_google_sheets`;
    static youtubesSource: string = `${base}/upload_youtube`;
    static createImage: string = `${base}/create_image`;
    static googleDocsSource: string = `${base}/upload_google_docs`;
    static deleteSource: string = `${query}/delete_namespaces`;
    static getPdfBuffer: string = `${base}/get_pdf`;
    static getPdfTable: string = `${base}/get_pdf_table`;
    static getPatho: string = `${base}/patho_namespaces`;
    static uploadPatho: string = `${base}/patho_upload_pdf`;
    static getPathoMongo: string = `${base}/patho_mongo`;
    static getPathologyPdf: string = `${base}/get_patho_pdf`;
    static chatHistoryRoute: string = `${base}/get_chat_history`;
    static postUserDatatoDB: string = `${apiURL}/users/v1/users`;
    static getUserInfo: string = `${apiURL}/users/v1/profile`;
    static uploadReportExcel: string = `${base}/upload_lmd_excel`;
    static uploadedExcelReports: string = `${base}/get_all_lmd_files`;
    static getCompanyIDs: string = `${base}/get_lmd_company_ids`;
    static updatedReportURL: string = `${base}/get_lmd_report`;
    static variance: string = `${base}/ingest_variance_excel`;
    static getVariance: string = `https://struct-api-ssx.softsensor.ai/structured_data/get_variance_report`;
    static data_lake: string = `${base}/load_data_lake`;
    static parameters: string = `${base}/list_all_parameters_and_ranges`;
    static complianceReport: string = `${base}/upload_compl_pdf`;
    static highlightedLMD: string = `${base}/get_highlighted_lmd_report?page=1`;
    static ExcelFile: string = `${base}/get_complete_alert_generation_data`;
    static UploadClaims: string = `${base}/upload_claims_pdfs`;
    static GetClaim: string = `${base}/claims_namespaces`;
    static ClaimReport: string = `${base}/get_claims_report`;
    static audioTranscript: string = `https://softx-demo.softsensor.ai/bond_llm/transcribe`;
    static audioKey: string = `${base}/post_audio_to_bot`;
    static complianceDocs: string = `${base}/compliance_namespaces`;
    static videoAudioDetails: string = `${base}/get_audio_video_details`;
    static GRIparameters: string = `${base}/get_GRI_parameters`;
    static henkelFilesUpload: string = `https://softxapi-demo.softsensor.ai/bond_llm/upload_henkel_files`;
    static getStreamingResponse: string = `https://softxapi-demo.softsensor.ai/bond_llm/get-progress`;
    static henkelGraphApi: string = `https://softxapi-demo.softsensor.ai/bond_llm/henkle_graph_api`;
    static henkelImageClassification: string = `https://softx-demo.softsensor.ai/bond_llm/detect_can_quality`;
    static henkelFileUploadProgressEvent: string = `https://softxapi-demo.softsensor.ai/bond_llm/get-progress/`;
    static trialBalanceRoute: string = `https://struct-api-ssx.softsensor.ai/structured_data/get_trial_balance_data`;
    static get_scenario_analysis_dropdown_route: string = `https://struct-api-ssx.softsensor.ai/structured_data/get_scenario_analysis_dropdown`;
    static damage_detection_file_upload_url: string = `https://unstruct-api-ssx.softsensor.ai/unstructured_data/post_images_to_inspection`;
    static pptURL: string = "https://struct-api-ssx.softsensor.ai/structured_data/generate_ppt";
    static logURL = (page: any) => `https://struct-api-ssx.softsensor.ai/structured_data/log_ids_list?page=${page}`;
    static logAnalysisURL: string = "https://struct-api-ssx.softsensor.ai/structured_data/log_analysis";
    static cyberLogInsight: string = "https://struct-api-ssx.softsensor.ai/structured_data/log_insights";
    static damage_detection_mobile_app_url: string = `https://softx-demo.softsensor.ai/bond_llm/test_image_metrics`;
    static damage_detection_file_upload_status: string = `https://softx-demo.softsensor.ai/bond_llm/check_progress`;
    static questionsRouteForDamageDetection: string = `https://softx-demo.softsensor.ai/bond_llm/detect-damage`;
    static tafIngestedFilesURL: string = `${UNSTRUCTURED_URL}/get-taf-files`;
    static tafChatQuery: string = `${UNSTRUCTURED_URL}/taf_discussion`;

    static getPPT(data: any) {
        return apiPromise(() => instance.post(this.pptURL, data));
    }
    static getLogAnalysis(data: any) {
        return apiPromise(() => instance.post(this.logAnalysisURL, data));
    }
    static getClaimsExistingProjects(data: any) {
        return apiPromise(() => instance.post(this.claimExistingProjects, data));
    }
    static getClaimsExistingProjectsPdf(data: any) {
        return apiPromise(() => instance.post(this.claimExistingProjectsPdf, data));
    }
    static getClaimsExistingProjectsPdfSummary(data: any) {
        return apiPromise(() => instance.post(this.claimExistingProjectsPdfSummary, data));
    }
    static getClaimsExistingProjectsFilter(data: any) {
        return apiPromise(() => instance.post(this.claimExistingProjectsFilter, data));
    }
    static getClaimsExistingProjectsPdfReference(data: any) {
        return apiPromise(() => instance.post(this.claimExistingProjectsPdfReference, data));
    }
    static getClaimsExistingProjectsPdfCompare(data: any) {
        return apiPromise(() => instance.post(this.claimExistingProjectsPdfCompare, data));
    }
    static getClaimsExistingPdfLibrary(data: any) {
        return apiPromise(() => instance.post(this.claimExistingPdfLibrary, data));
    }
    static getClaimsExistingDelayEvent(data: any, source?: any) {
        return apiPromise(() => instance.post(this.claimExistingDelayEvent, data, { cancelToken: source }));
    }

    static uploadDamageDetectionFiles(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.damage_detection_file_upload_url, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }

    static uploadDamageDetectionFilesRTS(data: any) {
        return apiPromise(() => instance.post(this.damage_detection_mobile_app_url, data));
    }

    static uploadQuestionsForDamageDetection(data: any) {
        return apiPromise(() => instance.post(this.questionsRouteForDamageDetection, data));
    }

    static uploadClaimCreateLibraryProject(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.claimCreateLibraryProject, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }
    static compareResponse(data: any) {
        return apiPromise(() => instance.post(this.compareandcontrast, data));
    }

    static careUpload(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.care_upload, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }
    static getCareDetail(file_id: string) {
        return apiPromise(() => instance.get(`${this.get_care_detail}/${file_id}`));
    }

    static getUsers() {
        return apiPromise(() => instance.get("https://jsonplaceholder.typicode.com/users"));
    }

    static getTrailBalanceData() {
        return apiPromise(() => instance.post(this.trialBalanceRoute));
    }
    static get_scenario_analysis_dropdown_data() {
        return apiPromise(() => instance.post(this.get_scenario_analysis_dropdown_route));
    }

    static getPdfs(data: any) {
        return apiPromise(() => instance.post(this.baseUrl, data));
    }
    static getPdfFile(data: any) {
        return apiPromise(() => instance.post(this.getPdfBuffer, data));
    }

    static getPdfTableContent(data: any) {
        return apiPromise(() => instance.post(this.getPdfTable, data));
    }

    static getAudioVideoDetails(data: any) {
        return apiPromise(() => instance.post(this.videoAudioDetails, data));
    }

    static uploadPdf(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.upload, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }

    static uploadHenkelFiles(formData: any) {
        return apiPromise(() =>
            instance.post(this.henkelFilesUpload, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }

    static henkelGraphGeneration(data: any) {
        return apiPromise(() => instance.post(this.henkelGraphApi, data));
    }

    static ImageClassification(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.henkelImageClassification, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }

    static getAudioTranscript(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.audioTranscript, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }

    static postAudioToBot(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.audioKey, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }

    static uploadComplianceReport(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.complianceReport, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }

    static uploadVariaceReport(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.variance, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }

    static GetHighlightedLMDReport(data: any) {
        return apiPromise(() => instance.post(this.highlightedLMD, data));
    }

    static uploadFinaceExcel(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.postFinanceExcel, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }
    static anamolyChatResponse(data: any) {
        return apiPromise(() => instance.post(this.anomaly_chat_bot, data));
    }
    static getDetectAnomalies() {
        return apiPromise(() => instance.get(this.detect_anomalies));
    }
    static getTafIngestedFiles() {
        return apiPromise(() => instance.get(this.tafIngestedFilesURL));
    }
    static getTafQueryResponse(data: any) {
        return apiPromise(() => instance.post(this.tafChatQuery, data));
    }
    static getDetectOutliers() {
        return apiPromise(() => instance.get(this.detect_outliers));
    }
    static getLogsInsight() {
        return apiPromise(() => instance.get(this.cyberLogInsight));
    }
    static getGRIParameters() {
        return apiPromise(() => instance.get(this.GRIparameters));
    }
    static getExcelFile(data: any) {
        return apiPromise(() => instance.post(this.ExcelFile, data));
    }
    static getDetectRounded(data: { page: number; size: number }) {
        return apiPromise(() => instance.get(this.detect_rounded(data)));
    }
    static getCybersecurityLogs(data: { page: any }) {
        return apiPromise(() => instance.get(this.logURL(data)));
    }
    static getDetectCreditsDebits() {
        return apiPromise(() => instance.get(this.detect_credits_debits));
    }
    static getDetectWeeklyGap() {
        return apiPromise(() => instance.get(this.detect_weekly_gap));
    }
    static getDetecExceptionalEntries() {
        return apiPromise(() => instance.get(this.detect_exceptional_entries));
    }

    static getVariaceReport() {
        return apiPromise(() => instance.get(this.getVariance));
    }
    static uploadPathology(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.uploadPatho, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }
    static getPathologyMongo(data: any) {
        return apiPromise(() => instance.post(this.getPathoMongo, data));
    }

    static getParameters(data: any) {
        return apiPromise(() => instance.post(this.parameters, data));
    }

    static getComplianceDocs(data: any) {
        return apiPromise(() => instance.post(this.complianceDocs, data));
    }

    // BSG  API's
    static getTables() {
        return apiPromise(() => instance.get(this.getAllTables));
    }

    static uploadESGexcel(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.uploadESGExcel, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }

    static uploadExcelForReportGeneration(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.uploadReportExcel, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }

    static getUploadedExcelReports() {
        return apiPromise(() => instance.post(this.uploadedExcelReports));
    }

    static getExcelReportsIDs() {
        return apiPromise(() => instance.get(this.getCompanyIDs));
    }

    static getAllLMDFiles() {
        return apiPromise(() => instance.get(this.uploadedExcelReports));
    }

    static getReport(data: any) {
        return apiPromise(() => instance.post(this.updatedReportURL, data));
    }

    static uploadMongoData(formData: any) {
        return apiPromise(() => instance.post(this.mongoSource, formData));
    }

    static uploadGoogleDocsData(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.googleDocsSource, formData, {
                headers: { "Content-Type": "mutipart/form-data" },
            })
        );
    }

    static uploadGoogleSheetsData(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.googleSheetsSource, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }

    static uploadYoutubeData(formData: any) {
        return apiPromise(() => instance.post(this.youtubesSource, formData));
    }

    // static getGeneralData(data: any) {
    //     return apiPromise(() => instance.post(this.getGeneralQuery, { question: data?.query }));
    // }
    // static searchResult(data: any) {
    //     return apiPromise(() => instance.post(this.askSearch, { question: data?.query }));
    // }
    // static getVisualizedData(data: any) {
    //     return apiPromise(() => instance.post(this.getVisualizedQuery, { question: data?.query }));
    // }
    static generateAIImages(data: any) {
        return apiPromise(() => instance.post(this.createImage, data));
    }
    static deleteSourceESG(data: any) {
        return apiPromise(() => instance.post(this.deleteSource, data));
    }
    static getPathoNamespaces(data: any) {
        return apiPromise(() => instance.post(this.getPatho, data));
    }

    static uploadPathologyReport(formData: any) {
        return apiPromise(() =>
            instance.post(this.uploadPatho, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }
    static getPathologyReport(data: any) {
        return apiPromise(() => instance.post(this.getPathologyPdf, data, { responseType: "arraybuffer" }));
    }

    static uploadUserData(data: any) {
        return apiPromise(() => instance.post(this.postUserDatatoDB, data));
    }

    static getChatHistory(data: any) {
        return apiPromise(() => instance.post(this.chatHistoryRoute, data));
    }

    // data lake
    static loadDataLake(data: any) {
        return apiPromise(() => instance.post(this.data_lake, data));
    }

    static uploadClaimsFiles(formData: FormData) {
        return apiPromise(() =>
            instance.post(this.UploadClaims, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }
    static getClaimNamespaces(data: any) {
        return apiPromise(() => instance.post(this.GetClaim, data));
    }
    static getClaimReport(data: any) {
        return apiPromise(() => instance.post(this.ClaimReport, data));
    }

    // finance
    static uploadCFOfiles(formData: FormData) {
        return apiPromise(() =>
            instance.post(`${STRUCTURED_URL}/cfo_doc_ingestion`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
        );
    }
    static chatCFO(data: any) {
        return apiPromise(() => instance.post(`${STRUCTURED_URL}/cfo_doc_ai_bot`, data));
    }

    // medical summarizer
    static chatMed(data: any) {
        return apiPromise(() => instance.post(`${STRUCTURED_URL}/medical_summarizer_bot`, data));
    }
    static checkInitStatus(task_id: string) {
        return apiPromise(() => instance.get(`${STRUCTURED_URL}/task_status/${task_id}`));
    }
    static getDocument(file_name: string, type: "pdf" | "docx") {
        return apiPromise(() =>
            instance.post(
                `${STRUCTURED_URL}/get_matched_file`,
                { file_name },
                type === "pdf" ? { responseType: "arraybuffer" } : {}
            )
        );
    }
}
