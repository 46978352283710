import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/setting";
import { Api } from "../../../apis";
import { Call, ExpandMore, Save } from "@mui/icons-material";
import { IconArrowBadgeRight } from "@tabler/icons-react";

const Logs = ({ setType }: { setType: any }) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [logs, setLogs] = useState<{ call_log: any[]; reminders: any[] }>({ call_log: [], reminders: [] });

    useEffect(() => {
        fetchLoags();
    }, [contextStore?.ai_contact_center?.ongoing]);

    const fetchLoags = () => {
        Api?.fetchLogs()?.then((res: any) => {
            if (res?.status === 200) {
                const response = res?.data?.call_log?.map((item: any) => ({ ...item, unsaved: false }));
                setLogs((prev: any) => ({
                    ...prev,
                    call_log: [...contextStore?.ai_contact_center?.ongoing, ...response],
                    reminders: [...res?.data?.reminders],
                }));
            } else {
                setLogs({ call_log: [], reminders: [] });
            }
        });
    };

    return (
        <Stack p={2} gap={2} flex={1} direction={"row"}>
            <Stack sx={{ border: "1px solid #E0E0E0", borderRadius: "8px 8px 0px 0px", flex: 2, p: 2 }}>
                <Typography color={"#007BFF"} variant="h5" sx={{ "& *": { fontFamily: "Gilroy-SemiBold" } }}>
                    Call Log
                </Typography>
                <Typography variant="caption" color={"#9D9B9B"}>
                    List of all the calls/ Customers records.
                </Typography>
                <Stack sx={{ flex: "1 0 10vh", overflowY: "auto", my: 1 }}>
                    {logs?.call_log?.map((item) => (
                        <>
                            <Stack direction={"row"} alignItems={"center"} py={2} px={4} gap={1.5}>
                                <Typography
                                    noWrap
                                    sx={{ width: "40%", cursor: "pointer" }}
                                    onClick={() => {
                                        setType("detail");
                                        setContext({
                                            ...contextStore,
                                            ai_contact_center: { ...contextStore?.ai_contact_center, selectedId: item },
                                        });
                                    }}
                                >
                                    {item?.call_id}
                                </Typography>
                                <Typography
                                    sx={{ fontSize: "0.8rem", alignContent: "center", color: "#484848", width: "25%" }}
                                >
                                    {moment(item?.timestamp)?.format("dddd HH:MM a")}
                                </Typography>
                                <Typography
                                    noWrap
                                    sx={{
                                        fontSize: "0.8rem",
                                        alignContent: "center",
                                        color: "#484848",
                                        width: "20%",
                                    }}
                                >
                                    {moment(item?.timestamp).fromNow()}
                                </Typography>
                                {!item?.is_processed && (
                                    <Stack flex={1} alignItems={"flex-end"} gap={1} sx={{ position: "relative" }}>
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "2px",
                                                right: "2px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div className="callbusy"></div>
                                        </div>
                                        <div className="shake">
                                            <Call fontSize="small" sx={{ fill: "#9d9d9d" }} />
                                        </div>
                                    </Stack>
                                )}
                            </Stack>
                            <Divider sx={{ borderColor: "#F3F3F4", mx: 2, borderBottomWidth: "2px" }} />
                        </>
                    ))}
                </Stack>
            </Stack>
            <Stack sx={{ border: "1px solid #E0E0E0", borderRadius: "8px 8px 0px 0px", p: 2, flex: 1 }}>
                <Typography color={"#007BFF"} variant="h5" sx={{ "& *": { fontFamily: "Gilroy-SemiBold" } }}>
                    Upcoming reminders
                </Typography>
                <Typography variant="caption" color={"#9D9B9B"}>
                    List of all the reminders created
                </Typography>
                <Stack sx={{ flex: "1 0 10vh", overflowY: "auto", my: 1, p: 0.5 }}>
                    {logs?.reminders?.map((item) => (
                        <Accordion
                            sx={{
                                "& .MuiAccordionSummary-root.Mui-expanded": {
                                    minHeight: "auto",
                                },
                                "& .MuiButtonBase-root": {
                                    minHeight: "0px",
                                },
                                "& .MuiPaper-root": {
                                    boxShadow: "0px 0px 3px 0px #00000052",
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                sx={{
                                    "& .MuiAccordionSummary-content": {
                                        justifyContent: "space-between",
                                        gap: 2,
                                        my: 1,
                                    },
                                }}
                            >
                                <Typography>{item?.call_id}</Typography>
                                <Typography
                                    noWrap
                                    sx={{ fontSize: "0.8rem", alignContent: "center", color: "#484848" }}
                                >
                                    {item?.date} {item?.time}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: 1 }}>
                                <Stack direction={"row"} alignItems={"center"} gap={2}>
                                    <IconArrowBadgeRight style={{ stroke: "rgb(170 170 170)", width: "16px" }} />
                                    <Typography variant="caption">{item?.task}</Typography>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    {logs?.reminders && logs?.reminders?.length === 0 && (
                        <Stack justifyContent={"center"} alignItems={"center"} p={2}>
                            <Typography>No Reminder Recieved</Typography>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Logs;
