import {
    Box,
    Button,
    Chip,
    CircularProgress,
    IconButton,
    OutlinedInput,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { Context } from "../../context/setting";
import SendWhiteIcon from "../../components/Common/svgLogs/SendWhiteIcon";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { ArrowRight, ChevronLeftRounded, ChevronLeftSharp, ChevronRightRounded } from "@mui/icons-material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "../../index.css";
import PdfLoader from "./PdfLoader";
import { Api } from "../../apis";
import ProfileAvatar from "../../components/Common/svgLogs/ProfileAvatar";
import SoftsensorOnlyXIcon from "../../components/Common/svgLogs/SoftsensorOnlyXIcon";

interface Props {
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const ChatScreen = ({ setActiveTab }: Props) => {
    const videoRef = useRef<HTMLVideoElement | any>(null);
    const scrollContainerRef = useRef<any>(null);
    const [query, setQuery] = useState<string>("");
    const { contextStore, setContext } = useContext<any>(Context);
    const [currentFile, setCurrentFile] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isScrolledLeft, setIsScrolledLeft] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [msgList, setMsgList] = useState<any>([]);
    const [currentSession, setCurrentSession] = useState<string>(Math.floor(1000 + Math.random() * 9000).toString());

    const postQuery = () => {
        setLoading(true);
        setMsgList([
            ...msgList,
            {
                msg: query,
                type: "out",
            },
        ]);

        Api?.getTafQueryResponse({
            question: `${query}`,
            userid: contextStore?.profile?.userId,
            sessionid: currentSession,
        }).then((res: any) => {
            if (res.status === 200) {
                setMsgList([
                    ...msgList,
                    {
                        msg: query,
                        type: "out",
                        status: "success",
                    },
                    {
                        msg: res?.data,
                        type: "in",
                        status: "success",
                    },
                ]);
                setQuery("");
                setLoading(false);
            } else {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: res?.data?.msg || "Something Went Wrong",
                    },
                });
                setLoading(false);
                setMsgList([
                    ...msgList,
                    {
                        msg: query,
                        type: "out",
                        status: "success",
                    },
                    {
                        msg: "Some error occurred for this query! Please try after some time",
                        type: "in",
                        status: "error",
                    },
                ]);
            }
        });
    };

    const handlePlay = (timeStamp: string) => {
        // Seek to the start time before playing
        const minutes: any = timeStamp.split(":");
        let seconds: any;
        if (minutes.length === 3) {
            seconds = Number(minutes[2]) + Number(minutes[1]) * 60 + Number(minutes[0]) * 3600;
        } else if (minutes.length === 2) {
            seconds = Number(minutes[0]) * 60 + Number(minutes[1]);
        }
        if (videoRef.current) {
            videoRef.current.currentTime = seconds; // Set the video start time
            videoRef.current.play();
        }
    };

    function timeToSeconds(time: any) {
        let seconds: any = time.split(":").map(Number);
        if (seconds.length === 1) {
            seconds = seconds;
        } else if (seconds.length === 2) {
            seconds = seconds[1] + seconds[0] * 60;
        } else {
            seconds = seconds[2] + seconds[1] * 60 + seconds[0] * 3600;
        }
        return seconds;
    }

    const highlightTimestamp = (text: any) => {
        const timestampRegex = /\b(\d{2}:\d{2}:\d{2}|\d{2}:\d{2})\b/g;

        return text.split(timestampRegex).map((part: any, index: any) => {
            if (timestampRegex.test(part)) {
                return (
                    <span
                        key={index}
                        style={{ color: "#007BFF", fontWeight: "600", cursor: "pointer" }}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            if (videoRef.current) videoRef.current.seekTo(timeToSeconds(part), "seconds");
                        }}
                    >
                        {" "}
                        {part}
                    </span>
                );
            } else {
                return (
                    <span key={index} style={{ color: "#000", fontWeight: "600" }}>
                        {" "}
                        {/* Change color to blue */}
                        {part}
                    </span>
                );
            }
        });
    };

    const renderers = {
        // Customize how strong text is rendered
        strong: ({ children }: any) => {
            const childText = React.Children.map(children, (child) =>
                typeof child === "string" ? child : child.props.children
            ).join("");
            return <>{highlightTimestamp(childText) || children}</>; // Highlight timestamps only
        },
    };

    const handleFileChange = (file: any, page?: any) => {
        setCurrentFile(file);
        if (page) {
            if (file?.file_type === "video") {
                handlePlay(page);
            } else if (file?.file_type === "pdf") {
                setPageNumber(page);
            } else if (file?.file_type === "pptx") {
                setPageNumber(page);
            } else if (file?.file_type === "docx") {
                setPageNumber(page);
            }
        }
    };

    return (
        <Box sx={{ width: "100%", pl: 4, pt: 4, pb: 4 }}>
            <ReflexContainer orientation="vertical">
                <ReflexElement className="left-pane">
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", mb: "5px" }}>
                        <Button sx={{ color: "#007BFF" }} onClick={() => setActiveTab("files")}>
                            <Box sx={{ display: "flex" }}>
                                <ChevronLeftSharp /> Back
                            </Box>
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            width: "100%",
                            height: useMediaQuery("(max-width:1450px)") ? "55vh" : "70vh",
                            overflowY: "scroll",
                            overflowX: "clip",
                        }}
                    >
                        {msgList.length === 0 && (
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                    width: "95%",
                                    ml: 1,
                                    mb: "1vh",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Stack gap={1} sx={{ border: "1px solid #D0D0D0", borderRadius: "10px", p: 2 }}>
                                    <Typography>Let’s get started!</Typography>
                                    <Stack direction={"row"} gap={3}>
                                        <Stack gap={0.5} width={"50%"}>
                                            <Typography variant="caption">Try Asking</Typography>
                                            {[
                                                "How is International Overhead Cost (IOC) allocated to project workdays?",
                                                "How are non-chargeable days like PTO handled in the IOC calculation?",
                                                "How is the rate for IOC determined once the costs are allocated?",
                                            ]?.map((msg) => (
                                                <Typography
                                                    key={msg}
                                                    sx={{
                                                        background: "rgba(217, 217, 217, 0.3)",
                                                        p: 1,
                                                        borderRadius: "4px",
                                                        fontSize: "0.75rem",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {msg}
                                                </Typography>
                                            ))}
                                        </Stack>
                                        <Stack gap={0.5}>
                                            <Typography variant="caption" sx={{ color: "#fff" }}>
                                                Limitations
                                            </Typography>
                                            {[
                                                "How are facilities and logistics costs separately accounted for in the IOC allocation process?",
                                                "How can the integration of ERP and FP&A tools streamline IOC rate calculation?",
                                                "How does the current manual process for IOC allocation using spreadsheets affect accuracy and efficiency?",
                                            ]?.map((msg) => (
                                                <Typography
                                                    key={msg}
                                                    sx={{
                                                        background: "rgba(217, 217, 217, 0.3)",
                                                        p: 1,
                                                        borderRadius: "4px",
                                                        fontSize: "0.75rem",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {msg}
                                                </Typography>
                                            ))}
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>
                        )}
                        {msgList.length > 0 &&
                            msgList?.map((msg: any) => {
                                return msg?.type === "out" ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: 2,
                                            width: "100%",
                                            ml: 1,
                                            mb: "1vh",
                                            alignItems: "center",
                                        }}
                                    >
                                        <ProfileAvatar />
                                        <Typography>{`${
                                            msg?.msg?.charAt(0).toUpperCase() + msg?.msg?.slice(1)
                                        }`}</Typography>
                                    </Box>
                                ) : msg?.type === "in" ? (
                                    typeof msg?.msg === "string" ? (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: 2,
                                                width: "95%",
                                                ml: 1,
                                                mb: 1,
                                                alignItems: "center",
                                            }}
                                        >
                                            <SoftsensorOnlyXIcon />
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    border: "1px dotted #D0D0D0",
                                                    p: 1,
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                {msg?.msg}
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: 2,
                                                width: "95%",
                                                ml: 1,
                                                mb: 1,
                                            }}
                                        >
                                            <SoftsensorOnlyXIcon />
                                            <Box
                                                sx={{
                                                    width: "95%",
                                                    height: "100%",
                                                    mb: "1vh",
                                                    border: "1px dotted #D0D0D0",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <Typography sx={{ py: 1, px: 1.4 }} variant="h6">
                                                    Main Answer
                                                </Typography>
                                                <Box sx={{ width: "100%", px: 1.4, pb: 1.4 }}>
                                                    <Markdown
                                                        className="markdown"
                                                        components={renderers}
                                                        remarkPlugins={[remarkGfm]}
                                                    >
                                                        {msg?.msg?.main_answer}
                                                    </Markdown>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        borderRadius: "10px",
                                                        display: "flex",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: "20%",
                                                            borderRight: "1px dotted #D0D0D0",
                                                            display: "flex",
                                                            py: 1.4,
                                                            justifyContent: "center",
                                                            color: "#007BFF",
                                                            borderBottom: "1px solid #D0D0D0",
                                                            borderTop: "1px solid #D0D0D0",
                                                        }}
                                                    >
                                                        File Title
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: "80%",
                                                            py: 1.4,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            color: "#007BFF",
                                                            borderBottom: "1px solid #D0D0D0",
                                                            borderTop: "1px solid #D0D0D0",
                                                        }}
                                                    >
                                                        Results
                                                    </Box>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        borderRadius: "10px",
                                                    }}
                                                >
                                                    {msg?.msg?.["sub-answer"]?.map((item: any) => {
                                                        return (
                                                            <Box sx={{ width: "100%", display: "flex" }}>
                                                                <Box
                                                                    sx={{
                                                                        width: "20%",
                                                                        borderRight: "1px dotted #D0D0D0",
                                                                        display: "flex",
                                                                        p: 1,
                                                                        justifyContent: "center",
                                                                        borderBottom: "1px dotted #D0D0D0",
                                                                        textOverflow: "clip",
                                                                        flexDirection: "column",
                                                                    }}
                                                                >
                                                                    <Typography>
                                                                        {item?.file_name.replace(/_/g, " ")}
                                                                    </Typography>
                                                                    <Box
                                                                        sx={{
                                                                            color: "#fff",
                                                                            background:
                                                                                item?.file_type === "pdf"
                                                                                    ? "rgba(255, 0, 0, 0.6)"
                                                                                    : item?.file_type === "docx"
                                                                                      ? "rgba(0, 0, 255, 0.5)"
                                                                                      : item?.file_type === "excel"
                                                                                        ? "rgba(6, 92, 6, 0.6)"
                                                                                        : item?.file_type === "pptx"
                                                                                          ? "rgba(248, 79, 13, 0.5)"
                                                                                          : "rgba(0, 0 , 0, 0.5)",
                                                                            width: "45px",
                                                                            height: "15px",
                                                                            fontSize: "12px",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            borderRadius: "5px",
                                                                        }}
                                                                    >
                                                                        {item?.file_type}
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        width: "80%",
                                                                        p: 1,
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        borderBottom: "1px dotted #D0D0D0",
                                                                        flexDirection: "column",
                                                                    }}
                                                                >
                                                                    <Markdown
                                                                        className="markdown"
                                                                        components={renderers}
                                                                        remarkPlugins={[remarkGfm]}
                                                                    >
                                                                        {item?.answer}
                                                                    </Markdown>
                                                                    {item?.file_type === "pdf" ? (
                                                                        item?.metadata?.page_no?.length > 0 ? (
                                                                            <Box sx={{ p: 1 }}>
                                                                                <Typography>Pages:</Typography>
                                                                                {item?.metadata?.page_no?.map(
                                                                                    (page: any) => {
                                                                                        return (
                                                                                            <Box
                                                                                                sx={{
                                                                                                    display: "flex",
                                                                                                    gap: 1,
                                                                                                }}
                                                                                            >
                                                                                                <ArrowRight />
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        color: "#007BFF",
                                                                                                        fontWeight:
                                                                                                            "600",
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        handleFileChange(
                                                                                                            item,
                                                                                                            page
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {page}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Box>
                                                                        ) : null
                                                                    ) : item?.file_type === "video" ? (
                                                                        item?.metadata?.timestamp.length > 0 ? (
                                                                            <Box sx={{ p: 1 }}>
                                                                                <Typography>Timestamps:</Typography>
                                                                                {item?.metadata?.timestamp?.map(
                                                                                    (time: any) => {
                                                                                        return (
                                                                                            <Box
                                                                                                sx={{
                                                                                                    display: "flex",
                                                                                                    gap: 1,
                                                                                                }}
                                                                                            >
                                                                                                <ArrowRight />
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        color: "#007BFF",
                                                                                                        fontWeight:
                                                                                                            "600",
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        handleFileChange(
                                                                                                            item,
                                                                                                            time
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {time}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Box>
                                                                        ) : null
                                                                    ) : item?.file_type === "excel" ? (
                                                                        item?.metadata?.sheet_name?.length > 0 ? (
                                                                            <Box sx={{ p: 1 }}>
                                                                                <Typography>Sheet name:</Typography>

                                                                                <Box sx={{ display: "flex", gap: 1 }}>
                                                                                    <ArrowRight />
                                                                                    <Typography
                                                                                        sx={{
                                                                                            color: "#007BFF",
                                                                                            fontWeight: "600",
                                                                                            cursor: "pointer",
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            setCurrentFile(item)
                                                                                        }
                                                                                    >
                                                                                        {item?.metadata?.sheet_name}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        ) : null
                                                                    ) : item?.file_type === "docx" ? (
                                                                        item?.metadata?.page_no.length > 0 ? (
                                                                            <Box sx={{ p: 1 }}>
                                                                                <Typography>Pages:</Typography>
                                                                                {item?.metadata?.page_no?.map(
                                                                                    (page: any) => {
                                                                                        return (
                                                                                            <Box
                                                                                                sx={{
                                                                                                    display: "flex",
                                                                                                    gap: 1,
                                                                                                }}
                                                                                            >
                                                                                                <ArrowRight />
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        color: "#007BFF",
                                                                                                        fontWeight:
                                                                                                            "600",
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        handleFileChange(
                                                                                                            item,
                                                                                                            page
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {page}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Box>
                                                                        ) : null
                                                                    ) : item?.file_type === "pptx" ? (
                                                                        item?.metadata?.slide_number?.length > 0 ? (
                                                                            <Box sx={{ p: 1 }}>
                                                                                <Typography>Pages:</Typography>
                                                                                {item?.metadata?.slide_number?.map(
                                                                                    (page: any) => {
                                                                                        return (
                                                                                            <Box
                                                                                                sx={{
                                                                                                    display: "flex",
                                                                                                    gap: 1,
                                                                                                }}
                                                                                            >
                                                                                                <ArrowRight />
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        color: "#007BFF",
                                                                                                        fontWeight:
                                                                                                            "600",
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        handleFileChange(
                                                                                                            item,
                                                                                                            page
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {page}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Box>
                                                                        ) : null
                                                                    ) : null}
                                                                </Box>
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                                <Box sx={{ width: "100%", p: 1, display: "flex", gap: 2 }}>
                                                    <Typography>Response files</Typography>
                                                    <Box
                                                        ref={scrollContainerRef}
                                                        onScroll={() => {
                                                            if (scrollContainerRef?.current) {
                                                                if (scrollContainerRef?.current?.scrollLeft > 0)
                                                                    setIsScrolledLeft(true);
                                                                else setIsScrolledLeft(false);
                                                            }
                                                        }}
                                                        sx={{
                                                            width: "100%",
                                                            display: "flex",
                                                            height: "55px",
                                                            overflowX: "hidden",
                                                            overflowY: "hidden",
                                                            whiteSpace: "nowrap",
                                                            gap: 2,
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {isScrolledLeft && (
                                                            <Box
                                                                sx={{
                                                                    left: 0,
                                                                    background:
                                                                        "linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 20%)",
                                                                    position: "sticky",
                                                                    minHeight: "55px",
                                                                    minWidth: "40px",
                                                                    borderTopLeftRadius: "21px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <ChevronLeftRounded
                                                                    sx={{ color: "blue" }}
                                                                    onClick={() => {
                                                                        if (scrollContainerRef.current) {
                                                                            scrollContainerRef.current.scrollLeft -= 100; // Adjust the amount as needed
                                                                        }
                                                                    }}
                                                                />
                                                            </Box>
                                                        )}
                                                        {msg?.msg?.["sub-answer"]?.map(
                                                            (answers: any, index: number) => {
                                                                return (
                                                                    <Typography
                                                                        sx={{
                                                                            width: "100%",
                                                                            background:
                                                                                currentFile?.file_name !==
                                                                                answers?.file_name
                                                                                    ? "#F3F3F4"
                                                                                    : "#E3F4FF",
                                                                            p: 0.5,
                                                                            cursor: "pointer",
                                                                            borderRadius: "10px",
                                                                            ml: index === 0 ? 1 : 0,
                                                                            mr: 1,
                                                                            color:
                                                                                currentFile?.file_name !==
                                                                                answers?.file_name
                                                                                    ? "#747474"
                                                                                    : "#007BFF",
                                                                        }}
                                                                        onClick={() => {
                                                                            setCurrentFile(answers);
                                                                            setPageNumber(1);
                                                                        }}
                                                                        variant="body2"
                                                                    >
                                                                        {answers?.file_name}
                                                                    </Typography>
                                                                );
                                                            }
                                                        )}
                                                        <Box
                                                            sx={{
                                                                right: 0,
                                                                background:
                                                                    "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 20%)",
                                                                position: "sticky",
                                                                minHeight: "55px",
                                                                minWidth: "40px",
                                                                borderTopRightRadius: "21px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <ChevronRightRounded
                                                                onClick={() => {
                                                                    if (scrollContainerRef.current) {
                                                                        scrollContainerRef.current.scrollLeft += 100; // Adjust the amount as needed
                                                                    }
                                                                }}
                                                                sx={{ color: "blue" }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                ) : null;
                            })}

                        {loading ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                    width: "95%",
                                    ml: 1,
                                    mb: 1,
                                    alignItems: "center",
                                }}
                            >
                                <SoftsensorOnlyXIcon />

                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ color: "#007BFF", fontSize: "0.93rem", fontWeight: 600 }}>
                                        Just a moment...
                                    </Typography>
                                    <Typography sx={{ color: "#B6B6B6", fontSize: "0.875rem" }}>
                                        Generating accurate results for you in no time...
                                    </Typography>
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            bottom: "10px",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            sx={{ width: "80%", border: "1px solid #E0E0E0", borderRadius: "21px", overflow: "hidden" }}
                        >
                            <OutlinedInput
                                value={query}
                                onChange={(e: { target: { value: string }; preventDefault: any }) => {
                                    setQuery(e.target.value);
                                }}
                                onKeyDown={(e: any) => {
                                    if (e?.key === "Enter") {
                                        if (e?.target?.value?.length === 0) {
                                            setContext({
                                                ...contextStore,
                                                snackState: {
                                                    open: true,
                                                    errorType: "warning",
                                                    message: "Please Ask A Question",
                                                },
                                            });
                                        } else {
                                            setQuery(e?.target?.value);
                                            postQuery();
                                            setQuery("");
                                        }
                                    }
                                    return;
                                }}
                                fullWidth
                                endAdornment={
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                        <IconButton
                                            sx={{ background: "#007BFF", "&:hover": { background: "#007BFFaa" } }}
                                            onClick={(e: any) => {
                                                if (query?.length === 0) {
                                                    setContext({
                                                        ...contextStore,
                                                        snackState: {
                                                            open: true,
                                                            errorType: "warning",
                                                            message: "Please Ask A Question",
                                                        },
                                                    });
                                                } else {
                                                    postQuery();
                                                    setQuery("");
                                                }
                                            }}
                                        >
                                            <SendWhiteIcon />
                                        </IconButton>
                                    </Box>
                                }
                                sx={{
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderRadius: "80px",
                                        border: "1px solid #E0E0E0",
                                    },
                                    "& .MuiOutlinedInput-input": {
                                        padding: "10px 20px",
                                    },
                                    width: "100%",
                                }}
                                placeholder="Please ask your query"
                            />
                        </Box>
                    </Box>
                </ReflexElement>

                {currentFile !== null && (
                    <ReflexSplitter>
                        <Box
                            sx={{
                                width: "2px",
                                height: "100%",
                                background: "#E3F4FF",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                borderWidth: "0px 2px 0px 2px",
                                borderStyle: "solid",
                                borderColor: "#E3F4FF",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "3px",
                                    height: "15px",
                                    background: "#0000001C",
                                    borderRadius: "10px",
                                }}
                            ></Box>
                            <Box
                                sx={{
                                    width: "3px",
                                    height: "15px",
                                    background: "#0000001C",
                                    borderRadius: "10px",
                                }}
                            ></Box>
                        </Box>
                    </ReflexSplitter>
                )}
                {currentFile !== null && (
                    <ReflexElement className="right-pane" minSize={400} size={650}>
                        <Box
                            sx={{
                                width: "90%",
                                display: "flex",
                                justifyContent: "space-between",
                                paddingInline: "15px 5px",
                                mb: "1vh",
                            }}
                        >
                            <Typography>
                                {currentFile?.file_name?.length > 40
                                    ? `${currentFile?.file_name?.substring(0, 40)}...`
                                    : currentFile?.file_name}
                            </Typography>
                            <Typography
                                sx={{ cursor: "pointer", color: "#007BFF" }}
                                onClick={() => setCurrentFile(null)}
                            >
                                Close
                            </Typography>
                        </Box>
                        {currentFile?.file_type === "excel" ? (
                            <DocViewer
                                style={{ width: "100%", height: "100%" }}
                                documents={[
                                    {
                                        uri: currentFile?.file_path,
                                        fileType: "xls",
                                    },
                                ]}
                                pluginRenderers={DocViewerRenderers}
                            />
                        ) : currentFile?.file_type === "pptx" ? (
                            <PdfLoader file={currentFile?.file_path} pageNumber={pageNumber} />
                        ) : currentFile?.file_type === "video" ? (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    mt: 5,
                                    ml: 1,
                                }}
                            >
                                <video
                                    width="100%"
                                    height="50%"
                                    controls
                                    style={{ borderRadius: "15px" }}
                                    ref={videoRef}
                                >
                                    <source src={currentFile?.file_path} type="video/mp4" />
                                    Your browser does not support the videos.
                                </video>
                            </Box>
                        ) : currentFile?.file_type === "pdf" ? (
                            <PdfLoader file={currentFile?.file_path} pageNumber={pageNumber} />
                        ) : currentFile?.file_type === "docx" ? (
                            <PdfLoader file={currentFile?.file_path} pageNumber={pageNumber} />
                        ) : null}
                    </ReflexElement>
                )}
            </ReflexContainer>
        </Box>
    );
};

export default ChatScreen;
