import { Box, Button, Modal, Typography } from "@mui/material";
import { Api } from "../../apis";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/setting";

interface Props {
    value: string;
    searchInput: string;
}

const TabsAndFileRenderer = ({ value, searchInput }: Props) => {
    const { contextStore, setContext } = useContext<any>(Context);
    const [files, setFiles] = useState<any>(null);
    const [filteredFiles, setFilteredFiles] = useState<any>(null);

    const getFiles = () => {
        Api?.getTafIngestedFiles()?.then((res: any) => {
            if (res?.status === 200) {
                setFiles(res?.data);
            } else {
                setContext({
                    ...contextStore,
                    snackState: {
                        open: true,
                        errorType: "error",
                        message: res?.message || "Something Went Wrong",
                    },
                });
            }
        });
    };

    useEffect(() => {
        getFiles();
    }, []);

    const getFilteredFiles = () => {
        if (files !== null) {
            if (value === "all") {
                return files?.filter((file: any) => file?.filename.toLowerCase().includes(searchInput.toLowerCase()));
            } else {
                return files?.filter(
                    (file: any) =>
                        file?.extension === value && file?.filename.toLowerCase().includes(searchInput.toLowerCase())
                );
            }
        }
    };

    useEffect(() => {
        setFilteredFiles(getFilteredFiles());
    }, [value, files, searchInput]);

    return (
        <Box sx={{ height: "90%", overflowY: "scroll" }}>
            {filteredFiles !== null &&
                filteredFiles?.map((file: any) => {
                    return (
                        <Box
                            sx={{
                                width: "100%",
                                height: "4vh",
                                minHeight: "60px",
                                borderBottom: "1px solid #F3F3F4",
                                display: "flex",
                                alignItems: "center",
                                p: "0 2vw",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography>{file?.filename}</Typography>
                        </Box>
                    );
                })}
        </Box>
    );
};

export default TabsAndFileRenderer;
