import React, { useState, useEffect, useRef, ChangeEvent, useContext } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    Link,
    TextField,
    IconButton,
    Popover,
    Button,
    Modal,
    FormHelperText,
    Checkbox,
    Button as MuiButton,
} from "@mui/material";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import UserIcon from "../../components/Common/svgLogs/HR_Analysis/UserIcon";
import XLogo from "../../components/Common/svgLogs/HR_Analysis/XLogo";
import FilterIcon from "../../components/Common/svgLogs/HR_Analysis/FilterIcon";

import ProgressBar from "./components/ProgressBar";
import { Context } from "../../context/setting";

interface ResumeData {
    resumeName: string; // not in use anymore
    similarityScore: number;
    analysis: string;
    filePath: string;
    name: string;
}

interface ApiResponse {
    list_of_dicts: ResumeData[];
    job_description: string;
}

const AnalysisResult: React.FC = () => {
    const [resumes, setResumes] = useState<ResumeData[]>([]);
    const [filteredResumes, setFilteredResumes] = useState<ResumeData[]>([]);
    const [totalResumes, setTotalResumes] = useState<number>(0);
    const [topResults, setTopResults] = useState<string>("");
    const [minSimilarityScore, setMinSimilarityScore] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [expandedAnalysis, setExpandedAnalysis] = useState<number | null>(null);
    const [jobDescription, setJobDescription] = useState<string>("Job Description");
    const [isJobDescriptionModalOpen, setIsJobDescriptionModalOpen] = useState(false);
    const [tempTopResults, setTempTopResults] = useState<string>("");
    const [tempMinSimilarityScore, setTempMinSimilarityScore] = useState<string>();
    const [topResultsError, setTopResultsError] = useState<string>("");
    const [minSimilarityScoreError, setMinSimilarityScoreError] = useState<string>("");

    const [selectedResumes, setSelectedResumes] = useState<string[]>([]);
    const navigate = useNavigate();
    const filterIconRef = useRef<HTMLButtonElement>(null);

    const { contextStore, setContext } = useContext<any>(Context);
    // const userId = contextStore?.profile?.userId;
    console.log(contextStore?.profile?.userId);

    useEffect(() => {
        const fetchResumes = async () => {
            try {
                const response = await axios.get<ApiResponse>(
                    `https://softxapi-demo.softsensor.ai/bond_llm/apply_filtering?user_id=${contextStore?.profile?.userId}`
                );
                if (response.data && response.data.list_of_dicts) {
                    setResumes(response.data.list_of_dicts);
                    setFilteredResumes(response.data.list_of_dicts);
                    setTotalResumes(response.data.list_of_dicts.length);
                    setJobDescription(response.data.job_description || "Job Description");
                } else {
                    setError("Unexpected data format from server!!");
                }
            } catch (error) {
                console.error("Error fetching resumes:", error);
                setError("Failed to fetch resume data");
            }
        };

        fetchResumes();
    }, []);

    const applyFilter = (topResultsValue: string, minScoreValue: string) => {
        let filtered = [...resumes];
        const minScore = parseInt(minScoreValue) || 0;
        filtered = filtered.filter((resume) => resume.similarityScore >= minScore);
        filtered.sort((a, b) => b.similarityScore - a.similarityScore);

        const topResultsNum = parseInt(topResultsValue);
        if (!isNaN(topResultsNum) && topResultsNum > 0) {
            filtered = filtered.slice(0, topResultsNum);
        }

        setFilteredResumes(filtered);
    };

    const handleFilterClick = () => {
        setAnchorEl(filterIconRef.current);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const handleFilterApply = () => {
        if (topResultsError || minSimilarityScoreError) {
            return;
        }
        setTopResults(tempTopResults);
        setMinSimilarityScore(tempMinSimilarityScore || "");
        applyFilter(tempTopResults, tempMinSimilarityScore || "");
        setSelectedResumes([]);
        handleFilterClose();
    };

    const handleClearFilters = () => {
        setTempTopResults("");
        setTempMinSimilarityScore("");
        setTopResults("");
        setMinSimilarityScore("");
        setTopResultsError("");
        setMinSimilarityScoreError("");
        setFilteredResumes(resumes);
        setSelectedResumes([]);
    };

    const toggleAnalysis = (index: number) => {
        setExpandedAnalysis(expandedAnalysis === index ? null : index);
    };

    const handleOpenJobDescriptionModal = () => {
        setIsJobDescriptionModalOpen(true);
    };

    const handleCloseJobDescriptionModal = () => {
        setIsJobDescriptionModalOpen(false);
    };

    const formatJobDescription = (description: string) => {
        const paragraphs = description.split("\n").filter((p) => p.trim() !== "");

        return paragraphs.map((paragraph, index) => (
            <Typography key={index} paragraph>
                {paragraph.trim()}
            </Typography>
        ));
    };

    // const truncateText = (text: string, maxLength: number) => {
    //     if (text.length <= maxLength) return text;
    //     return text.slice(0, maxLength) + "...";
    // }; not required as JD reformat

    const handleTopResultsChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTempTopResults(value);
        if (value === "") {
            setTopResultsError("");
        } else {
            const numValue = parseInt(value);
            if (isNaN(numValue) || numValue < 1 || numValue > totalResumes - 1) {
                setTopResultsError(`Please enter a number between 1 and ${totalResumes - 1}.`);
            } else {
                setTopResultsError("");
            }
        }
    };

    const handleMinSimilarityScoreChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTempMinSimilarityScore(value);
        if (value === "") {
            setMinSimilarityScoreError("");
        } else {
            const numValue = parseInt(value);
            if (isNaN(numValue) || numValue < 0 || numValue > 100) {
                setMinSimilarityScoreError("Please enter a number between 0 and 100.");
            } else {
                setMinSimilarityScoreError("");
            }
        }
    };

    const handleCheckboxChange = (filePath: string) => {
        setSelectedResumes((prev) =>
            prev.includes(filePath) ? prev.filter((path) => path !== filePath) : [...prev, filePath]
        );
    };

    const handleSubmit1 = () => {
        return console.log("Resume Submitted");
    };

    const handleSubmit = async () => {
        try {
            await axios.post("https://lmdapiendpoint.com/random_path/shortlisted_resume", {
                selectedResumes,
                userId: contextStore?.profile?.userId,
            });
            console.log("Resumes submitted successfully");
        } catch (error) {
            setContext({
                ...contextStore,
                snackState: {
                    open: true,
                    errorType: "error",
                    message: "Server not available!!",
                },
            });
        }
    };

    const open = Boolean(anchorEl);

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Box sx={{ width: "100%", height: "100%", p: "5vh 10vw" }}>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box display="flex" alignItems="center">
                        <UserIcon />
                        <Typography
                            fontSize={"16px"}
                            sx={{
                                marginLeft: 2,
                                color: "#007BFF",
                                cursor: "pointer",
                                "&:hover": {
                                    textDecoration: "underline",
                                },
                            }}
                            onClick={handleOpenJobDescriptionModal}
                        >
                            Job Description
                        </Typography>
                    </Box>
                    <IconButton onClick={handleFilterClick} ref={filterIconRef}>
                        <FilterIcon />
                    </IconButton>
                </Box>

                {/* Job Description Modal */}
                <Modal
                    open={isJobDescriptionModalOpen}
                    onClose={handleCloseJobDescriptionModal}
                    aria-labelledby="job-description-modal"
                    aria-describedby="full-job-description"
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "80%",
                            maxWidth: "1200px",
                            maxHeight: "90vh",
                            bgcolor: "background.paper",
                            border: "2px solid #000",
                            boxShadow: 24,
                            p: 4,
                            overflowY: "auto",
                        }}
                    >
                        <Typography id="job-description-modal" variant="h6" component="h2">
                            Job Description
                        </Typography>
                        <Typography id="full-job-description" sx={{ mt: 2, mb: 2 }}>
                            {formatJobDescription(jobDescription)}
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button onClick={handleCloseJobDescriptionModal} variant="contained">
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Modal>

                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                    <Box display="flex" alignItems="center">
                        <XLogo />
                        <Typography fontSize={"15px"} sx={{ marginLeft: 2 }}>
                            {filteredResumes.length} candidates matched
                        </Typography>
                    </Box>
                    <Typography fontSize={"15px"}>Total {totalResumes} candidates.</Typography>
                </Box>

                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleFilterClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <Box sx={{ p: 2, width: 300 }}>
                        <Typography variant="h6" component="h2" mb={2}>
                            Filter Options
                        </Typography>
                        <TextField
                            type="text"
                            label="Top Candidates"
                            value={tempTopResults}
                            onChange={handleTopResultsChange}
                            fullWidth
                            sx={{ mb: 2 }}
                            placeholder={`Enter value between 1 to ${totalResumes - 1}`}
                            error={!!topResultsError}
                        />
                        <TextField
                            type="text"
                            label="Match Score"
                            value={tempMinSimilarityScore}
                            onChange={handleMinSimilarityScoreChange}
                            inputProps={{ min: 0, max: 100 }}
                            fullWidth
                            sx={{ mb: 2 }}
                            placeholder="Enter value between 0 to 100"
                            error={!!minSimilarityScoreError}
                        />
                        {minSimilarityScoreError && <FormHelperText error>{minSimilarityScoreError}</FormHelperText>}
                        {topResultsError && <FormHelperText error>{topResultsError}</FormHelperText>}

                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Button variant="outlined" onClick={handleClearFilters} sx={{ width: "48%" }}>
                                Clear Filters
                            </Button>
                            <Button variant="contained" onClick={handleFilterApply} sx={{ width: "48%" }}>
                                Apply Filters
                            </Button>
                        </Box>
                    </Box>
                </Popover>

                <Box>
                    <TableContainer
                        component={Paper}
                        sx={{
                            maxHeight: "600px",
                            overflowY: "auto",
                            borderRadius: "4px",
                            "&::-webkit-scrollbar": {
                                width: "8px",
                            },
                            "&::-webkit-scrollbar-track": {
                                background: "#f1f1f1",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                background: "#888",
                                borderRadius: "4px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                background: "#555",
                            },
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: "#747474", fontSize: "16" }}>Name</TableCell>
                                    <TableCell sx={{ color: "#747474", fontSize: "16" }}>Resume</TableCell>
                                    <TableCell sx={{ color: "#747474", fontSize: "16" }}>Match Score</TableCell>
                                    <TableCell sx={{ color: "#747474", fontSize: "16" }}>Analysis</TableCell>
                                    {/* <TableCell sx={{ color: "#747474", fontSize: "16" }}>Shortlist</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredResumes.length > 0 ? (
                                    filteredResumes.map((resume, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={{ fontSize: "16" }}>{resume.name}</TableCell>
                                            <TableCell>
                                                <Link
                                                    href={`https://softxapi-demo.softsensor.ai/bond_llm/get_resume_pdf?resume_path=${encodeURIComponent(
                                                        resume.filePath
                                                    )}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {`Resume ${index + 1}.pdf`}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex" alignItems="center">
                                                    <ProgressBar percentage={resume.similarityScore} />
                                                    <Typography sx={{ marginLeft: 2, fontSize: "16" }}>
                                                        {resume.similarityScore}%
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                {expandedAnalysis === index
                                                    ? resume.analysis
                                                    : `${resume.analysis.slice(0, 100)}...`}
                                                <Button
                                                    onClick={() => toggleAnalysis(index)}
                                                    sx={{ ml: 1, fontSize: "16" }}
                                                >
                                                    {expandedAnalysis === index ? "read less" : "read more"}
                                                </Button>
                                            </TableCell>
                                            {/* <TableCell>
                                                <Checkbox
                                                    sx={{
                                                        "&.Mui-checked": {
                                                            color: "#2E7D32",
                                                        },
                                                    }}
                                                    checked={selectedResumes.includes(resume.filePath)}
                                                    onChange={() => handleCheckboxChange(resume.filePath)}
                                                />
                                            </TableCell> */}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <Typography align="center">No resume found!</Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <Box mt={2} display="flex" justifyContent="flex-end">
                        <MuiButton variant="contained" onClick={handleSubmit} disabled={selectedResumes.length === 0}>
                            Shortlist {selectedResumes.length} Resumes
                        </MuiButton>
                    </Box> */}
                </Box>
            </Box>
        </Box>
    );
};

export default AnalysisResult;
