import { useState } from "react";
import ChatScreen from "./ChatScreen";
import IntroScreen from "./IntroScreen";
import FilesContent from "./FilesContent";

const Taf = () => {
    const [activeTab, setActiveTab] = useState<string>("intro");
    return activeTab == "intro" ? (
        <IntroScreen setActiveTab={setActiveTab} />
    ) : activeTab === "files" ? (
        <FilesContent setActiveTab={setActiveTab} />
    ) : (
        <ChatScreen setActiveTab={setActiveTab} />
    );
};

export default Taf;
